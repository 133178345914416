import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import xlsx from "xlsx";
import moment from "moment";
import { format } from "date-fns";
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";

//import Firebase from "../../../providers/firebaseProvider";

import { database, } from "../../../providers/use-auth";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
  Spinner,
  ListGroup,
} from "react-bootstrap";

/// imge
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";

import whatsapp from "../../../images/qr.png";

import {
  inviteMemberAction,
  bulkInviteMemberAction,
  cancelInvitationAction,
  getInvitationsAction,
  resetAllAction,
} from "../../../store/actions/MemberActions";

import { getProfile } from "../../../store/actions/AuthActions";

import axiosInstance from "../../../services/AxiosInstance";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const DevicePage = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [qr, setQr] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const [name, setName] = useState("");
  const [activeId, setActiveId] = useState("");

  const [changeNameModal, setChangeNameModal] = useState(false);

  const profile = useSelector((state) => state.auth.profile);

  const [instances, setInstances] = useState([]);

  let errorsObj = { name: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [selectedDevice, setSelectedDevice] = useState("");
  const [listener, setListener] = useState(null);

  useEffect(() => {
    loadInstance();
  }, [selectedDevice, dispatch]);

  //   useEffect(() => {
  //    //dispatch(getProfile());
  //    loadInstance();
  //  }, [dispatch]);

  const loadInstance = async () => {
    const { data } = await axiosInstance.post("message/instances");

    if (data.status) {
      setInstances(data.inatances);

      data.inatances.map((instance, i) => {
        initQRListener(instance.id, data.inatances);
      });
    }
  };

  useEffect(() => { }, [profile]);

  const onGetQR = async (id) => {
    setSelectedDevice(id);
    console.log("selected id - " + id)

    const { data } = await axiosInstance.post("message/qr-instance", {
      instance_id: id,
    });

    if (data.status) {
      //setCampaigns(data.campaigns);
      console.log("qr generated");
    } else {
      console.log("qr not generated");
    }
  };

  const onLogout = async (id) => {
    setSelectedDevice(id);
    console.log("selected id - " + id)
    const { data } = await axiosInstance.post("message/logout-instance", {
      instance_id: id,
    });

    if (data.status) {
      //setCampaigns(data.campaigns);
      console.log("logout success");
      swal("Logout", data.message, "success");
    } else {
      console.log("logout failed");
      swal("Logout", data.message, "error");
    }
  };

  const onDeleteInstance = async (id) => {
    const { data } = await axiosInstance.get("message/delete-instance", {
      instance_id: id,
    });

    if (data.status) {
      //setCampaigns(data.campaigns);
      console.log("logout success");
    } else {
      console.log("logout failed");
    }
  };

  function onChangeName(instance) {
    setActiveId(instance.id);
    setName(instance.name);
    setChangeNameModal(true);
  }

  const onUpgradePlan = (instance) => {
    setActiveId(instance.id);
    setName(instance.name);
    history.push({
      pathname: '/upgrade-plan',
      state: { data: instance.id }
    });
  }

  const onSaveName = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          "message/change-instance-name",
          { name: name, instance_id: activeId }
        );

        if (data.status === false) {
          swal("Change Instance Name", data.message, "error");
        } else {
          setChangeNameModal(false);
          swal("Change Instance Name", data.message, "success");

          setTimeout(() => {
            loadInstance();
          }, 500);
        }
      } catch (error) { }
    }
  };

  const initQRListener = (id, inatanceList) => {
    var qrRef = database.ref("/sessions/" + id);

    // if(listener != null){
    //    qrRef.off("value", listener)
    // }

    var onDataChange = qrRef.on("value", function (snapshot) {
      //snapshot.forEach(function(childSnapshot) {

      if (snapshot) {
        var childKey = snapshot.key;
        var childData = snapshot.val();

        //console.log("data - "+childData);
        console.log("data json - " + JSON.stringify(childData));
        //console.log("data qr - "+childData.qr)



        if (childData) {

          console.log("selectedDevice - " + selectedDevice);
          console.log("childKey - " + childKey);
          console.log("status - " + childData.status ?? "");


          if (childData.qr) {
            //setQr(childData.qr);
            if (selectedDevice === childKey && childData.status === "QR") {
              setQr(childData.qr);
            } else {
              setQr("")
            }
          }

          if (childData.status) {
            //setStatus(childData.status??"Not Connected");

            if (
              selectedDevice === childKey &&
              (childData.status != "QR")
            ) {
              setQr("");
            }

            var dummy = [...inatanceList];

            const index = dummy.findIndex((x) => x.id === id);

            try {
              if (index != -1) {
                dummy[index].status = childData.status ?? "Not Ready";

                if (childData.message) {
                  dummy[index].message = childData.message ?? "";
                }

                setInstances(dummy);
              } else {
                setQr("");
              }
            } catch (e) {
              setQr("");
            }

            // if(childData.status == "authenticated"){
            //    setQr("");
            // }
          }
        }
      }

      //setQr("http://api.qrserver.com/v1/create-qr-code/?data="+encodeURI(childData.qr)+"&size=200x200&bgcolor=ffffff");

      // ...
      //});
    });

    setListener(onDataChange);
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={changeNameModal}
        onHide={setChangeNameModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveName}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit User</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setChangeNameModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setChangeNameModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title className="mr-auto p-2">Whatsapp Instant</Card.Title>

              <div class="d-flex justify-content-end"></div>
            </Card.Header>
            <Card.Body>
              {instances.length == 0 ? (
                <p>No Instance Found</p>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Instance Key</strong>
                      </th>
                      <th>
                        <strong>Name</strong>
                      </th>
                      <th>
                        <strong>Instance Status</strong>
                      </th>

                      <th>
                        <strong>Expire</strong>
                      </th>

                      <th>
                        <strong>Action</strong>
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {instances.map((instance, i) => (
                      <tr key={instance.code}>
                        <td>
                          <strong className="text-success">
                            {instance.code}
                          </strong>
                        </td>

                        <td>{instance.name ?? ""}</td>

                        <td>
                          <div className="">
                            {instance.status ?? ""}
                            <br />
                            <small>{instance.message ?? ""}</small>
                          </div>
                        </td>

                        <td>{moment(new Date(instance.expire)).format("DD-MM-YYYY HH:mm:ss")}</td>

                        <td>
                          {instance.status != "Ready" ? (
                            <Button
                              className="btn btn-success font-w600 mr-2"
                              onClick={() => onGetQR(instance.id)}
                            >
                              GET QR Code
                            </Button>
                          ) : (
                            <></>
                          )}

                          <Button
                            className="btn btn-primary font-w600 mr-2"
                            onClick={() => onLogout(instance.id)}
                          >
                            Logout
                          </Button>

                          {/* <Link className="btn btn-danger font-w600 mr-2" onClick={()=> onDeleteInstance(instance.id)}>Delete Instance</Link>
                           */}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="table-dropdown icon-false"
                            >
                              <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <circle
                                    fill="#000000"
                                    cx="5"
                                    cy="12"
                                    r="2"
                                  ></circle>
                                  <circle
                                    fill="#000000"
                                    cx="12"
                                    cy="12"
                                    r="2"
                                  ></circle>
                                  <circle
                                    fill="#000000"
                                    cx="19"
                                    cy="12"
                                    r="2"
                                  ></circle>
                                </g>
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                as="button"
                                onClick={(e) => onChangeName(instance)}
                              >
                                Change Name
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={(e) => onUpgradePlan(instance)}
                              >
                                Upgrade Plan
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title className="mr-auto p-2">
                For Login Whatsapp You Have To Do:
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      1. Open Whatsapp On Your Phone
                    </ListGroup.Item>
                    <ListGroup.Item>
                      2. Tap On Section or Settings And Select Linked Devices
                    </ListGroup.Item>
                    <ListGroup.Item>
                      3. If You Haven't Joined The Multi-device Beta Yet, You
                      Can Join First Link Tutorial Click Here
                    </ListGroup.Item>
                    <ListGroup.Item>
                      4. Point Your Phone To This Screen To Capture The Code.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span class="text-danger">
                        Note: Multi Device BETA Login Only Works{" "}
                      </span>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>

                <Col lg={6} className="text-center">
                  {qr != "" ? (
                    <img className="img" src={qr}></img>
                  ) : (
                    <img className="img img-fluid" src={whatsapp} />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DevicePage;
