import React from 'react'
import { Card } from "react-bootstrap";

const CommonCard = (props) => {
  const { header } = props
  return (
    <div>
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto p-2">{header}</Card.Title>
        </Card.Header>
      </Card>
    </div>
  )
}

export default CommonCard