import React, { useEffect, useState } from 'react'
import {
  Table,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosInstance from '../../../services/AxiosInstance';
import { useDispatch } from 'react-redux';

const transactionData = [
  {
    id: '301260',
    type: 'Failed',
    old_balance: '2400',
    credited: '1',
    total: '2401',
    currency: 'INR',
    paymentmode: 'Paytm',
    txn_id: '5464654646565',
    status: 'fail',
    remark: 'remark',
    created_at: '2024/01/16  17:38:32',
  },
  {
    id: '309220',
    type: 'success',
    old_balance: '2400',
    credited: '1',
    total: '2401',
    currency: 'INR',
    paymentmode: 'Paytm',
    txn_id: '5464654646565',
    status: 'fail',
    remark: 'remark',
    created_at: '2024/01/16  17:38:32',
  },
  {
    id: '3019260',
    type: 'rejected',
    old_balance: '2400',
    credited: '1',
    total: '2401',
    currency: 'INR',
    paymentmode: 'Paytm',
    txn_id: '5464654646565',
    status: 'fail',
    remark: 'remark',
    created_at: '2024/01/16  17:38:32',
  }, {
    id: '319226',
    type: 'Pending',
    old_balance: '2400',
    credited: '1',
    total: '2401',
    currency: 'INR',
    paymentmode: 'Paytm',
    txn_id: '5464654646565',
    status: 'fail',
    remark: 'remark',
    created_at: '2024/01/16  17:38:32',
  },
  {
    id: '0192260',
    type: 'Failed',
    old_balance: '2400',
    credited: '1',
    total: '2401',
    currency: 'INR',
    paymentmode: 'Paytm',
    txn_id: '5464654646565',
    status: 'fail',
    remark: 'remark',
    created_at: '2024/01/16  17:38:32',
  },
  {
    id: '3192260',
    type: 'success',
    old_balance: '2400',
    credited: '1',
    total: '2401',
    currency: 'INR',
    paymentmode: 'Paytm',
    txn_id: '5464654646565',
    status: 'fail',
    remark: 'remark',
    created_at: '2024/01/16  17:38:32',
  },
  {
    id: '3092260',
    type: 'rejected',
    old_balance: '2400',
    credited: '1',
    total: '2401',
    currency: 'INR',
    paymentmode: 'Paytm',
    txn_id: '5464654646565',
    status: 'fail',
    remark: 'remark',
    created_at: '2024/01/16  17:38:32',
  }, {
    id: '3012260',
    type: 'Pending',
    old_balance: '2400',
    credited: '1',
    total: '2401',
    currency: 'INR',
    paymentmode: 'Paytm',
    txn_id: '5464654646565',
    status: 'fail',
    remark: 'remark',
    created_at: '2024/01/16  17:38:32',
  },
]

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [transactionData, setTransactionData] = useState([])
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [totalTransaction, setTotalTransaction] = useState()
  const [itemsPerPage, setItemsPerPage] = useState(5)

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        onLoadTransaction()
      }, 2000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search, activePage, dispatch]);

  useEffect(() => {
    if (transactionData) {
      setLoading(false);
    }
  }, [transactionData]);

  const onLoadTransaction = async () => {
    setLoading(true);
    const response = await axiosInstance.post("message/payment/get/all", {
      search: search,
    });
    if (response.status) {
      setTransactionData(response.data.data);
      setTotalTransaction(response.data.pageInfo.totalItems)
    } else {
      setLoading(false);
    }
  };

  const onClick = (i) => {
    setActivePage(i);
  };

  const sort = 50;
  const totalPages = Math.ceil(totalTransaction / itemsPerPage);
  // let pagination = Array(totalPages).fill().map((_, i) => i + 1);
  let pagination = Array(Math.ceil(transactionData.length / sort)).fill().map((_, i) => i + 1);

  // let pagination = Array(totalPages).fill().map((_, i) => i + 1);

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <div class="mr-auto">
            <h4 className="card-title">Transaction History</h4>
          </div>
          <div className="input-group search-area d-lg-inline-flex d-none mr-2">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (searchTimeout) {
                  clearTimeout(searchTimeout);
                }
                onLoadTransaction();
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </form>
            <div className="input-group-append">
              <span className="input-group-text">
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                    fill="#A4A4A4"
                  />
                </svg>
              </span>
            </div>
          </div>

        </div>
        <div className="card-body">
          {loading ? (
            <div className="text-center mt-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : transactionData.length == 0 ? (
            <p>No Transaction history found</p>
          ) : (
            <Table responsive className="w-100">
              <div id="example_wrapper" className="dataTables_wrapper">
                <table id="example" className="display w-100 dataTable">
                  <thead>
                    <tr role="row">
                      <th>Sr No.</th>
                      <th>Total</th>
                      <th>Currency</th>
                      <th>Payment Mode</th>
                      <th>TXN ID</th>
                      <th>Status</th>
                      <th>Remark</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionData.map((d, i) => (
                      <tr key={d.id}>
                        <td>{i + 1}</td>
                        <td>{d.amount}</td>
                        <td>{d.payment_currency}</td>
                        <td>{d.payment_mode}</td>
                        <td>{d.txn_id}</td>
                        <td>{d.status}</td>
                        <td>{d.remark}</td>
                        <td>{d.createdAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {activePage * sort + 1} to&nbsp;
                    {transactionData.length < (activePage + 1) * sort
                      ? transactionData.length
                      : (activePage + 1) * sort}
                    &nbsp;of {transactionData.length} entries
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers">
                    <Pagination
                      className="pagination-primary pagination-circle"
                      size="lg"
                    >
                      <li className="page-item page-indicator "
                        onClick={() => activePage > 1 && onClick(activePage - 1)}
                      >
                        <Link className="page-link" to="#">
                          <i className="la la-angle-left" />
                        </Link>
                      </li>
                      {pagination.map((number, i) => (
                        <Pagination.Item
                          key={i}
                          className={activePage === i ? "active" : ""}
                          onClick={() => onClick(i)}
                        >
                          {number}
                        </Pagination.Item>
                      ))}
                      <li
                        className="page-item page-indicator"
                        onClick={() => activePage + 1 < totalTransaction && onClick(activePage + 1)
                        }
                      >
                        <Link className="page-link" to="#">
                          <i className="la la-angle-right" />
                        </Link>
                      </li>
                    </Pagination>
                  </div>
                </div>
              </div>
            </Table>
          )}
        </div>
      </div>
    </div>
  )
}

export default TransactionHistory