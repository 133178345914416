import { ACTIVE_PLAN_DETAILS } from "../actions/PurchasePlanActions";

const plandata = {
  paymentDetails: {}
}

export default function purchasePlanReducer(state = plandata, action) {
  let newState = plandata;

  switch (action.type) {
    case ACTIVE_PLAN_DETAILS:

      newState = Object.assign({}, state, { paymentDetails: action.payload });
      return newState;
    default:
      return state;
  }
}