import React, { useState, useEffect, useRef } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import axiosInstance from '../../../services/AxiosInstance';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Button, Stack } from '@mui/material';
import { CircularProgress } from '@mui/material';

const validationSchema = yup.object({
  email: yup.string().required('Username is required'),
  name: yup.string().required('Name is required'),
  // gst_number: yup.string().required('GST Number is required'),
  number: yup.string().required('Mobile Number is required'),
  address1: yup.string().required('Address 1 is required'),
  address2: yup.string().required('Address 2 is required'),
  postal_code: yup.string().required('Postal Code is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
});

const Payform = (props) => {
  const { addPaymentModal, setAddPaymentModal, purchaseData, userData, instanceId } = props
  const { paymentDetails } = useSelector((state) => state.purchasePlanReducer)
  //for paytm
  const [txToken, setToken] = useState(undefined);
  const [mid, setMid] = useState(undefined);
  const [orderId, setOrderId] = useState(undefined);
  //for paypal
  const [showPayPalBtn, setShowPayPalBtn] = useState(false);
  const [payPalCurrency, setPayPalCurrency] = useState();
  const [paypalAmount, setPaypalAmount] = useState();
  const [paypalClientId, setPaypalClientId] = useState();
  const [loader, setLoader] = useState(false)
  const stringifyObj = JSON.stringify(userData)
  const encodedStringBtoA = btoa(stringifyObj);
  const userDetails = localStorage.getItem('userDetails');
  const initialUserDataDetails = JSON.parse(userDetails);

  const ButtonWrapper = ({ paypalsuccessFun, closeModel, amount, currency, showSpinner }) => {

    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          currency: currency,
        },
      });
    }, [currency, showSpinner]);

    return (<>
      {(showSpinner && isPending) && <div className="spinner" />}
      <PayPalButtons
        style={{
          layout: "horizontal",
          color: "gold",
          // shape: "pill",
          label: "pay",
          height: 40,
          width: 100,
          textAlign: 'center',
          fontSize: 14,
          marginLeft: '5px'
        }}
        disabled={false}
        forceReRender={[amount, currency]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function (data) {
            paypalsuccessFun(data);
            closeModel()
          });
        }}
        onInit={function (data, actions) {
          console.log("onInit", data)
          console.log("onInit", actions)
        }}
        onError={function (err) {
          console.log("onError", err)
        }}
        onClick={function (data, actions) {
          console.log("onClick", data)
          console.log("onClick", actions)
        }}
        onCancel={function (data, actions) {
          console.log("onCancel", data)
          console.log("onCancel", actions)
          setAddPaymentModal(false)
          setLoader(false)
          setShowPayPalBtn(false)
          swal('Payment Failed', '', 'error');
        }}
      />
    </>
    );
  }

  const handleClose = () => {
    setAddPaymentModal(false)
  }

  const onCloseModal = () => {
    setAddPaymentModal(false)
    formik.resetForm()
  }
  useEffect(() => {
    if (initialUserDataDetails && initialUserDataDetails?.user) {
      formik.setFieldValue("email", initialUserDataDetails?.user?.email);
      formik.setFieldValue("name", initialUserDataDetails?.user?.name);
      formik.setFieldValue("number", initialUserDataDetails?.user?.phone);
    }
  }, []);

  let subscription_id = btoa(purchaseData?._id);
  let currency_code = btoa(purchaseData?.countryData?.currency_code);
  const formik = useFormik({
    initialValues: {
      email: initialUserDataDetails?.user?.email || '',
      name: initialUserDataDetails?.user?.name || '',
      gst_number: '',
      number: initialUserDataDetails?.user?.phone || '',
      address1: '',
      address2: '',
      postal_code: '',
      state: '',
      city: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      const data = {
        email: formik?.values?.email,
        name: formik?.values?.name,
        gst_number: formik?.values?.gst_number,
        number: formik?.values?.number,
        address1: formik?.values?.address1,
        address2: formik?.values?.address2,
        postal_code: formik?.values?.postal_code,
        state: formik?.values?.state,
        city: formik?.values?.city,
      }
      try {
        if (!loader) {
          setLoader(true)
          if (purchaseData?.countryData?.gateway_type === "PAYTM") {
            const res = await axiosInstance.get(`message/paytm_paynow/${subscription_id}?currency_code=${currency_code}`, data);
            setToken(res?.data?.responseData?.txnToken);
            setMid(res?.data?.responseData?.mid);
            setOrderId(res?.data?.responseData?.orderId);
            redirectToPaytmModal(res?.data?.responseData?.txnToken, res?.data?.responseData?.orderId, res?.data?.responseData?.amount, res?.data?.responseData?.mid);
          } else if ((purchaseData?.countryData?.gateway_type === "PAYPAL")) {
            const res = await axiosInstance.post(`message/paypal_paynow/${subscription_id}?currency_code=${currency_code}`, data);
            setPaypalClientId(res?.data?.data?.paypal?.business);
            setPayPalCurrency(res?.data?.data?.paypal?.currency_code);
            // setPaypalAmount(res?.data?.data?.paypal?.amount);
            setPaypalAmount(1);
            setTimeout(() => {
              setShowPayPalBtn(true);
            }, 2000);
          }
        }
      } catch (error) {
        swal('Payment Failed', error, 'error');
        setShowPayPalBtn(false)
        setLoader(false)
      }
    },
  });

  const redirectToPaytmModal = (txnToken, orderId, amount, mid) => {
    console.log('amount', amount)
    var config = {
      "root": "",
      "style": {
        "bodyBackgroundColor": "#fafafb",
        "bodyColor": "",
        "themeBackgroundColor": "#0FB8C9",
        "themeColor": "#ffffff",
        "headerBackgroundColor": "#284055",
        "headerColor": "#ffffff",
        "errorColor": "",
        "successColor": "",
        "card": {
          "padding": "",
          "backgroundColor": ""
        }
      },
      "data": {
        "orderId": orderId,
        "token": txnToken,
        "tokenType": "TXN_TOKEN",
        "amount": amount
      },
      "payMode": {
        "labels": {},
        "filter": {
          "exclude": []
        },
        "order": [
          "CC",
          "DC",
          "NB",
          "UPI",
          "PPBL",
          "PPI",
          "BALANCE"
        ]
      },
      "website": "WEBSTAGING",
      "flow": "DEFAULT",
      "merchant": {
        "mid": mid,
        "redirect": false
      },
      "handler": {
        "transactionStatus":
          async function transactionStatus(paymentStatus) {

            console.log('paymentStatus', paymentStatus)
            // if (paymentStatus && paymentStatus.STATUS === "TXN_SUCCESS") {
            window.Paytm.CheckoutJS.close();
            try {
              const value = paymentStatus;
              if (instanceId !== undefined) {
                value.instance_id = instanceId;
              }
              const res = await axiosInstance.post(`message/paytm-payment-success/${subscription_id}/${encodedStringBtoA}`, value);
              setAddPaymentModal(false)
              setLoader(false)
              formik.resetForm()
            } catch (error) {
              formik.resetForm()
              setShowPayPalBtn(false)
              swal('Payment Failed', error, 'error');
            }
          },
        "notifyMerchant":
          function notifyMerchant(eventName, data) {
            formik.resetForm()
            setAddPaymentModal(false)
            setLoader(false)
            setShowPayPalBtn(false)
            swal('Payment Failed', '', 'error');
            console.log("Merchant notified:", eventName, data);
          }
      }
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
        window.Paytm.CheckoutJS.invoke();
      }).catch(function onError(error) {
        console.log("Error => ", error);
      });
    } else {
      //
    }
  };

  const handlePaypalSuccessResponse = async (data) => {
    const mainData = data;
    if (instanceId !== undefined) {
      mainData.instance_id = instanceId;
    }
    try {
      const res = await axiosInstance.post(`message/paypalsuccess/${subscription_id}/${encodedStringBtoA}`, mainData);
      setLoader(false)
      setShowPayPalBtn(false)
      swal('Payment Successfully', res.data.message, 'success');
    } catch (error) {
      setLoader(false)
      setShowPayPalBtn(false)
      swal('Payment Failed', error, 'error');
    }
  }

  return (
    <div>
      <Modal
        size="lg"
        className="modal fade"
        show={addPaymentModal}
        onHide={setAddPaymentModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Personal Details</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAddPaymentModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="required-field">Username</label>
                        <input
                          type="text"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Username"
                          name="email"
                          className="form-control"
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger fs-12">{formik.errors.email}</div>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label className="required-field">Name</label>
                        <input
                          type="text"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Name"
                          name="name"
                          className="form-control"
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="text-danger fs-12">{formik.errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="required-field">Gst Number</label>
                        <input
                          type="text"
                          value={formik.values.gst_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Gst Number"
                          name="gst_number"
                          className="form-control"
                        />
                        {/* {formik.touched.gst_number && formik.errors.gst_number && (
                          <div className="text-danger fs-12">{formik.errors.gst_number}</div>
                        )} */}
                      </div>

                      <div className="form-group col-md-6">
                        <label className="required-field">Mobile Number</label>
                        <input
                          type="text"
                          value={formik.values.number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Mobile Number"
                          name="number"
                          className="form-control"
                        />
                        {formik.touched.number && formik.errors.number && (
                          <div className="text-danger fs-12">{formik.errors.number}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label className="required-field">Address 1</label>
                        <input
                          type="text"
                          value={formik.values.address1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Address 1"
                          name="address1"
                          className="form-control"
                        />
                        {formik.touched.address1 && formik.errors.address1 && (
                          <div className="text-danger fs-12">{formik.errors.address1}</div>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <label className="required-field">Address 2</label>
                        <input
                          type="text"
                          value={formik.values.address2}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Address 2"
                          name="address2"
                          className="form-control"
                        />
                        {formik.touched.address2 && formik.errors.address2 && (
                          <div className="text-danger fs-12">{formik.errors.address2}</div>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <label className="required-field">Postal Code</label>
                        <input
                          type="text"
                          value={formik.values.postal_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Postal Code"
                          name="postal_code"
                          className="form-control"
                        />
                        {formik.touched.postal_code && formik.errors.postal_code && (
                          <div className="text-danger fs-12">{formik.errors.postal_code}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="required-field">State</label>
                        <input
                          type="text"
                          value={formik.values.state}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="State"
                          name="state"
                          className="form-control"
                        />
                        {formik.touched.state && formik.errors.state && (
                          <div className="text-danger fs-12">{formik.errors.state}</div>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label className="required-field">City</label>
                        <input
                          type="text"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="City"
                          name="city"
                          className="form-control"
                        />
                        {formik.touched.city && formik.errors.city && (
                          <div className="text-danger fs-12">{formik.errors.city}</div>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              {/* <div> */}
              {!showPayPalBtn ?
                <div className="modal-footer">
                  <button
                    type="button"
                    // onClick={() => setAddPaymentModal(false)}
                    onClick={() => onCloseModal()}
                    className="btn btn-danger"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Discard
                  </button>
                  <button type="submit" className="btn btn-primary" style={{ width: '100px' }}>
                    {loader ? <CircularProgress size={16} style={{ color: '#ffffff' }} /> : "Save"}
                  </button>
                </div>
                :
                <div className="modal-footer">
                  <PayPalScriptProvider
                    options={{
                      "client-id": paypalClientId,
                      intent: "capture",
                      currency: payPalCurrency
                    }}
                  >
                    <ButtonWrapper
                      paypalsuccessFun={handlePaypalSuccessResponse}
                      closeModel={handleClose}
                      amount={paypalAmount}
                      currency={payPalCurrency}
                      showSpinner={false}
                    />
                  </PayPalScriptProvider>
                </div>
              }
            </form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Payform