import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Modal,
  Button,
  Row,
  Col,
  Form,
  ListGroup,
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { getProfile } from "../../../store/actions/AuthActions";

import messageTypeList from "../../../utils/message-type";

import { CSVLink } from "react-csv";

import DTable from "../../components/Table";

//const Orders = () => {
const Messages = ({ props, history }) => {
  const dispatch = useDispatch();
  const [messageData, setMessageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const csvInstance = useRef(null);


  const profile = useSelector((state) => state.auth.profile);

  const sort = 50;
  let pagination = Array(Math.ceil(messageData.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [addTemplateModal, setAddTemplateModal] = useState(false);

  const [message, setMessage] = useState("");
  let errorsObj = { instance: "", phone: "", message: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [selectedInstance, setSelectedInstance] = useState("");

  const [name, setName] = useState("");
  const [button1, setButton1] = useState("");
  const [button2, setButton2] = useState("");
  const [button3, setButton3] = useState("");

  const [footer, setFooter] = useState("");

  //menu
  const [middle, setMiddle] = useState("");
  const [button, setButton] = useState("");
  const [menu, setMenu] = useState([]);

  // const messageTypeList = [
  //    {
  //      id:0,
  //      title:"Text"
  //    },
  //    {
  //      id:2,
  //      title:"Quick Reply Button"
  //    }
  //  ];
  const [selectedType, setSelectedType] = useState(0);

  const [xlsData, setXlsData] = useState([]);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  useEffect(() => {
   if (
     xlsData &&
     csvInstance &&
     csvInstance.current &&
     csvInstance.current.link
   ) {
     setTimeout(() => {
       csvInstance.current.link.click();
       setXlsData([]);
     });
   }  }, [xlsData]);


  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadMesssages();
  }, [page, pageSize]);

  // Data Table End

  useEffect(() => {
    //dispatch(getProfile());
    loadMesssages();
  }, [dispatch]);

  useEffect(() => {
    if (messageData) {
      setLoading(false);
      setTableData(
        messageData.slice(activePage * sort, (activePage + 1) * sort)
      );
    }
  }, [messageData]);

  const loadMesssages = async () => {
    const { data } = await axiosInstance.post("message/received", {
      page: page,
      limit: pageSize,
    });

    if (data.status) {
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
      setMessageData(data.messages);
    }
  };

  //const [demo, setdemo] = useState();
  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(messageData.slice(i * sort, (i + 1) * sort));
  };

  async function asyncForEach(array, callback) {
   for (let index = 0; index < array.length; index++) {
     await callback(array[index], index, array);
   }
 }

  const initXlsData = async () => {
    var dummy = [];

    const { data } = await axiosInstance.post("message/received");

    if (data.status) {
      console.log("total messages - " + data.messages.length);

      var i = 0;
      await asyncForEach(data.messages, async (d) => {
    
         i++;

    //messageData.map((data, i) => {
      dummy.push({
        sr_no: i,
        instance:d.instanceId.code+"("+d.instanceId.name+")",
        number: d.number,
        message: d.message,
        received_at: format(new Date(d.createdAt), "dd/MM/yyyy H:mma"),
      });
    });

    setXlsData(dummy);

   }


  };

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },
    {
      Header: "Instance",
      accessor: "instanceId",
      //colspan:2,
      //width: getColumnWidth(rows, 'key', 'Key'),
      //style: { width: '40%', textAlign: 'center' },
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div>
            <b>{d.instanceId.code}</b>
            <br />({d.instanceId.name})
          </div>
        );

        //return <span style={{whiteSpace:"nowrap"}} onClick={() => {navigator.clipboard.writeText(d.key)}}>{d.key}</span>;
        //return <input  className="form-control" style={{ width: '40%', textAlign: 'center' }} type="text" value={d.key} readOnly={true}></input>;
      },
    },
    {
      Header: "Number",
      accessor: "number",
      sort: false,
    },

    {
      Header: "Message",
      accessor: "message",
      sort: false,
    },

    {
      Header: "Time",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },
  ];

  return (
    <>
      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div class="mr-auto">
                <h4 className="card-title">All Messages</h4>
              </div>

              {xlsData.length > 0 ? (
                <CSVLink
                  ref={csvInstance}
                  data={xlsData}
                  filename={"received-messages.csv"}
                  className="btn btn-sm btn-primary mr-2"
                  target="_blank"
                >
                  Export
                </CSVLink>
              ) : (
                <Button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => initXlsData()}
                >
                  Export
                </Button>
              )}

            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : messageData.length == 0 ? (
                <p>No Message Found</p>
              ) : (

               <DTable
                columns={columns}
                data={messageData}
                pageSize={pageSize}
                isSortable={true}
                pagination={false}
                pageSizes={pageSizes}
                page={page}
                maxPage={maxPage}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
              />)}

                {/* <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No.</th>
                          <th>Instance</th>
                          <th>Number</th>
                          <th>Message</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((d, i) => (
                          <tr key={d.id}>
                            <td>{i + 1}</td>
                            <td>
                              <b>{d.instanceId.code}</b>
                              <br />({d.instanceId.name})
                            </td>
                            <td>{d.number}</td>

                            <td>{d.message}</td>

                            <td>
                              {format(
                                new Date(d.createdAt),
                                "dd/MM/yyyy H:mma"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                          <th>Sr No.</th>
                          <th>Instance</th>
                          <th>Number</th>
                          <th>Message</th>

                          <th>Time</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to&nbsp;
                        {messageData.length < (activePage + 1) * sort
                          ? messageData.length
                          : (activePage + 1) * sort}
                        &nbsp;of {messageData.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table> */}
              
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Messages;
