import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axiosInstance from '../../../services/AxiosInstance';
import { Grid, Typography, Divider, Card, Button } from '@mui/material';
import CommonCard from "../../pages/CommonCard";
import Payform from "./Payform";
import { store } from "../../../store/store";
import { setPaymentDetails } from './../../../store/actions/PurchasePlanActions'

const button = {
  padding: '8px 16px',
  fontWeight: 500,
  backgroundColor: '#17556d',
  '&:hover': {
    backgroundColor: '#02394e',
  },
}

const PurchasePlan = () => {
  const dispatch = useDispatch();
  const [planList, setPlanList] = useState([])
  const [country, setCountry] = useState(94)
  const [countryListData, setCountryListData] = useState([])
  const [addPaymentModal, setAddPaymentModal] = useState(false)
  const [purchaseData, setPurchaseData] = useState()
  const [userData, setUserData] = useState()

  useEffect(() => {
    loadPlans()
  }, [dispatch, country])

  useEffect(() => {
    countryList()
  }, [dispatch])

  const countryList = async () => {
    const { data } = await axiosInstance.get("message/countries-list", {});
    if (data?.status) {
      setCountryListData(data?.countriesList);
    }
  };

  const loadPlans = async () => {
    const { data } = await axiosInstance.get(`message/get-subscription/${country}`, {});
    if (data.status) {
      setPlanList(data?.getAllPackage);
    }
  };

  const onPayment = async (data) => {
    setAddPaymentModal(true)
    setPurchaseData(data)
    const res = await axiosInstance.get(`message/active-plan/${data._id}`, {});
    console.log('res-=-=-=->', res)
    store.dispatch(setPaymentDetails(res.data.data));
    setUserData(res.data.data.userData)
  }

  return (
    <>
      <Grid sx={{ margin: '10px 0' }}>
        <CommonCard header={'Purchase Credit'} />
      </Grid>
      <Grid sx={{ margin: '10px 0' }}>
        <div className="form-group">
          <select
            style={{ width: '400px' }}
            className="form-control"
            id="inputState"
            placeholder="Country"
            value={country}
            defaultValue={country}
            onChange={(e) => {
              setCountry(e.target.value)
            }}
          >
            {countryListData.length === 0 ? (
              <option value="" disabled>
                No Data Found
              </option>
            ) : (
              countryListData.map((row) => (
                <option key={row?.id} value={row?.id}>
                  {row?.name}
                </option>
              ))
            )}
          </select>
        </div>
      </Grid >
      <Grid container spacing={2} sx={{ pt: 5 }}>
        {planList?.length === 0 ?
          <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
            <Typography variant="h6" fontWeight={"500"} >
              No Record Found.
            </Typography>
          </Grid>
          :
          <>
            {planList?.map((row) => {
              return (
                <>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ p: 5, textAlign: 'center', height: '100%' }}>
                      <Typography variant="h5" fontWeight={"500"}>
                        {row?.packageName}
                      </Typography>

                      <Typography variant="h5" fontWeight={"600"} padding={"10px 0"}>
                        {row?.total} {row?.countryData?.currency_code}
                      </Typography>

                      <Typography variant="subtitle1" fontWeight={"400"} padding={"10px 0"} fontSize={"14px"}>
                        <b>{row?.gst}%</b> : GST
                      </Typography>
                      <Divider />

                      <Typography variant="subtitle1" fontWeight={"400"} padding={"10px 0"} fontSize={"14px"}>
                        Total Cost : <b>{row?.totalCost} {row?.countryData?.currency_code} </b>
                      </Typography>
                      <Divider />

                      <Typography variant="subtitle1" fontWeight={"400"} padding={"10px 0"} fontSize={"14px"}>
                        Validity: <b>{row?.validity}</b>
                      </Typography>
                      <Divider />
                      <Typography variant="subtitle1" fontWeight={"400"} padding={"10px 0"} fontSize={"14px"}>
                        Whatsapp Instance: <b>{row?.description}</b>
                      </Typography>
                      <Divider />
                      <Grid padding={"25px 0 10px 0"}>
                        <Button variant="contained" onClick={() => onPayment(row)} component="label" sx={button} size='small'>
                          PAY NOW
                        </Button>
                      </Grid>
                    </Card >
                  </Grid>
                </>
              )
            })}
          </>
        }
      </Grid>
      <Payform addPaymentModal={addPaymentModal} setAddPaymentModal={setAddPaymentModal} purchaseData={purchaseData} userData={userData} />
    </>
  );
};

export default PurchasePlan;